<template>
  <div class="accountInfo">
    <div class="accountInfoTitle">
      <span>电子签章管理</span>
      <!-- 制作签章 -->
      <a
        target="_blank"
        :href="enterpriseLink"
        v-if="enterpriseLink&&status==2"
      >&nbsp;&nbsp;第三方认证地址 : {{ enterpriseLink ? enterpriseLink : '--' }}</a>
      <el-button
        v-if="enterpriseLink&&status==2"
        size="mini"
        style="font-size: 16px;padding: 4px 8px;"
        v-clipboard:copy="enterpriseLink"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >复制</el-button>
    </div>
    <div class="accountInfoContent">
      <el-row>
        <el-col :span="6">
          <div class="list">
            <p class="title">签章图片</p>
            <div>
              <img
                v-if="details.sealImgurl"
                class="signatureImage"
                :src="details.sealImgurl"
              />
              <span v-else>--</span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="list">
            <p class="title">签章名称</p>
            <p>{{ details.name }}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="list">
            <p class="title">契约锁签章ID</p>
            <p>{{ details.sealId }}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="list">
            <p class="title">签章上传时间</p>
            <p>{{ details.updateTime | DateFormate }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      details: {
        name: null,
        sealId: null,
        sealImgurl: null
      },
      enterpriseLink: null,
      status:0,
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getUserInfo();
    this.getIdentificationStatus();
  },
  methods: {
    getIdentificationStatus() {
      http
        .postFront(protocolFwd.param_enterpriseCertification)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (Object.keys(value).length !== 0) {
              // this.enterPriseFirmId = value.firm_id
              this.status = value.status
              if (value.status==2){
                  this.getEnterpriseLink(value.firm_id);
              }
            
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    onCopy() {
      this.$EL_MESSAGE("复制成功");
    },
    onError() {
      this.$EL_MESSAGE("复制失败");
    },
    getEnterpriseLink(id) {
      protocolFwd.param_companyAuth.param.firmId = id;
      http.postFront(protocolFwd.param_companyAuth).then(response => {
        const { code, message, value } = response.data;
        if(code==0){
            if(value=='2'){
              this.enterpriseLink = null;
            }else{
              this.enterpriseLink = value;
            }
        }else{
          this.$EL_MESSAGE(message)
        }
        
      });
    },
    getUserInfo() {
      protocolFwd.param_findOneSignature.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocolFwd.param_findOneSignature).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value) {
            this.details = value;
            for (let i in this.details) {
              if (!this.details[i]) {
                this.details[i] = "--";
              }
            }
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.accountInfo {
  line-height: 32px;
}
.accountInfo .accountInfoTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.accountInfo .accountInfoTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.accountInfoContent {
  width: 100%;
  margin: 15px;
  line-height: 40px;
}
.accountInfoContent .list {
  text-align: center;
}
.accountInfoContent .list .title {
  font-size: 16px;
  font-weight: 700;
}
.accountInfoContent .signatureImage {
  max-width: 150px;
  max-height: 150px;
}
</style>
